import React from "react"
import Layout from "../../components/layout"

const ServicePage = () => {
    return (
        <Layout title="PCB Design">
            <div className="mx-auto container px-4">
                <h1>PCB Design & Layout</h1>
                <hr className="mb-4" />
                <p>
                    <span className="font-bold">
                        Pre-layout Analysis/Feasibility Study
                    </span>{" "}
                    - Gathering all information early including board thickness
                    and stack-up. We study design requirements and preform quick
                    pre-analysis of design including a space case study of real
                    estate available and technology to be used. Combined with
                    best practices we quickly determine the best case scenario
                    for PCB design. Through modeling and simulation we provide
                    complete design rules for use in directing layout.
                </p>
                <p>
                    <span className="font-bold">
                        Layout of complex performance driven PCB design
                    </span>{" "}
                    with constraint driven rules derived from SI pre-layout
                    analysis. Constantly dynamically adjusting and addressing
                    design challenges and parameters per design case providing
                    real-time simulations during entire layout process.
                </p>

                <p>
                    <span className="font-bold">Post-layout Analysis</span> -
                    Using field solvers with board level signal integrity tools,
                    we provide complete post-layout, pre analysis and
                    comprehensive final design guidance for timing margins,
                    crosstalk, power integrity and signal integrity constraints.
                </p>
                <p>
                    <span className="font-bold">Advanced Layout</span> -
                    providing World-Class printed circuit board layout services
                    for advanced projects. Our engineering resources work
                    together with yours to ensure an implementation that is
                    "correct by construction, and our goal is to make the first
                    design the last design while achieving performance goals
                    verified through simulation.”
                </p>
            </div>
        </Layout>
    )
}

export default ServicePage
